import Vue from 'vue'
import ApprovalForm from '@/services/LogicEditor/infrastructure/components/forms/ApprovalForm/ApprovalForm.vue'
import LogicForm from '@/services/LogicEditor/infrastructure/components/forms/LogicForm.vue'
import StateForm from '@/services/LogicEditor/infrastructure/components/forms/StateForm/index.vue'
import LogicGroupForm from '@/services/LogicEditor/infrastructure/components/forms/LogicGroupForm.vue'
import ConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/ConstraintForm.vue'
import ViewConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/ViewConstraintForm.vue'
import DisableConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/DisableConstraintForm.vue'
import ListenerForm from '@/services/LogicEditor/infrastructure/components/forms/ListenerForm.vue'
import CommandForm from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/index.vue'
import RelatedObjectForm from '@/services/LogicEditor/infrastructure/components/forms/RelatedObjectForm/RelatedObjectForm.vue'
import FormulaForm from '@/services/LogicEditor/infrastructure/components/forms/FormulaForm.vue'
import RowFormulaForm from '@/services/LogicEditor/infrastructure/components/forms/RowFormulaForm.vue'
import ProcedureForm from '@/services/LogicEditor/infrastructure/components/forms/ProcedureForm/ProcedureForm.vue'

export default Vue.extend({
  components: {
    ApprovalForm,
    LogicForm,
    StateForm,
    LogicGroupForm,
    ConstraintForm,
    ViewConstraintForm,
    DisableConstraintForm,
    ListenerForm,
    CommandForm,
    RelatedObjectForm,
    FormulaForm,
    RowFormulaForm,
    ProcedureForm
  },

  computed: {
    approvalCreated () {
      return this.$store.getters['Approval/getApprovalLocation']
    },

    commandCreated () {
      return this.$store.getters['Command/getCommandLocation']
    },

    constraintCreated () {
      return this.$store.getters['Constraint/getConstraintLocation']
    },

    formulaCreated () {
      return this.$store.getters['Formula/getFormulaLocation']
    },

    listenerCreated () {
      return this.$store.getters['Listener/getListenerLocation']
    },

    logicCreated () {
      return this.$store.getters['Logic/getLogicLocation']
    },

    logicGroupCreated () {
      return this.$store.getters['LogicGroup/getLogicGroupLocation']
    },

    relatedObjectCreated () {
      return this.$store.getters['RelatedObject/getRelatedObjectLocation']
    },

    rowFormulaCreated () {
      return this.$store.getters['RowFormula/getRowFormulaLocation']
    },

    stateCreated () {
      return this.$store.getters['State/getStateLocation']
    },

    procedureCreated () {
      return this.$store.getters['Procedure/getProcedureLocation']
    }
  }
})
